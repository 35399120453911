<script setup>
import { computed, ref, watch } from 'vue';
import { fieldRequired, isNumber, compareValue, isWholeNumber } from '@/utils/input_validation'
import LabelledField from '@/components/utils/LabelledField.vue';
import { getFormattedMoney } from '@/utils/functions';

const props = defineProps({
    fees: {
        type: [Object, Array],
        default: null
    },
    fetchingFees: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['nextClicked'])

const feesHaveFeeCategories = computed(() => {
    return !Array.isArray(props.fees)
})

const feeCategories = computed(() => {
    if (!props.fetchingFees && feesHaveFeeCategories.value) {
        return Object.keys(props.fees)
    }
    return []
})

const feeValues = computed(() => {
    let values = []
    if (feesHaveFeeCategories.value) {
        const feeCategoryNotEmpty = !!selectedFeeCategory.value
        if (feeCategoryNotEmpty) {
            const feesForCategory = props.fees?.[selectedFeeCategory.value] ?? []
            values = feesForCategory
        }
        selectedFeeValue.value = null
    } else {
        values = props.fees
    }
    return values
})

const selectedFeeCategory = ref(null)

const selectedFeeCategoryNotEmpty = computed(() => {
    return !!selectedFeeCategory.value
})

const selectedFeeValue = ref(null)

const selectedFeeValueNotEmpty = computed(() => {
    return !!selectedFeeValue.value
})

const selectedFeeFieldDisabled = computed(() => {
    return feesHaveFeeCategories.value && !selectedFeeCategoryNotEmpty.value
})

const feeFieldMessage = computed(() => {
    let message = null
    const shouldSelectFeeCategory = feesHaveFeeCategories.value && !selectedFeeCategoryNotEmpty.value
    if (shouldSelectFeeCategory) {
        message = 'Please select a fee category first'
    }
    return message
})

const quantity = ref(1)

const quantityFieldMinValueRule = compareValue({
    getOtherValueFn: () => 0,
    operator: '>',
    errorMsg: 'Quantity must be greater than 0'
})

const quantityAllowsDecimalPlaces = computed(() => {
    return false
})

const feeIsPerYear = computed(() => {
    const perYearRegex = /Per\s*Year/i
    const isPerYearUom = !!selectedFeeValue.value?.uom?.match(perYearRegex)
    if (isPerYearUom) {
        quantity.value = 1
    }
    return isPerYearUom
})

const unitCost = computed(() => {
    if (selectedFeeValueNotEmpty.value) {
        return selectedFeeValue.value.amount
    }
    return null
})

const total = computed(() => {
    if (selectedFeeValueNotEmpty.value) {
        const feeAmount = selectedFeeValue.value.amount
        let selectedQuantity = feeIsPerYear.value ? 1 : quantity.value
        return Number(feeAmount) * selectedQuantity
    }
    return null
})

function getFeeName(fee) {
    return `${fee.fee_name} - ${fee.uom}`
}

function generateBillItem() {
    const fee = selectedFeeValue.value
    return {
        bill_name: fee.fee_name,
        fee_id: fee.id,
        unit_cost: fee.amount,
        uom: fee.uom,
        quantity: quantity.value,
        manual_amount_entry: fee.manual_amount_entry,
        sub_total: total.value
    }
}

const formRef = ref(null)

function onFormSubmitted() {
    const formValid = formRef.value.validate()
    if (formValid) {
        emit('nextClicked', generateBillItem())
    }
}
</script>

<template>
    <v-container fluid class="pa-0">
        <template v-if="!fetchingFees">
            <v-form ref="formRef" @submit.prevent="onFormSubmitted">
                <v-row v-if="feesHaveFeeCategories" justify="center">
                    <v-col cols="12" md="12">
                        <LabelledField label="Select a fee category" required>
                            <v-autocomplete :items="feeCategories" outlined dense hide-no-data hide-details="auto"
                                class="text-field-background" v-model="selectedFeeCategory" clearable
                                :rules="[fieldRequired]"></v-autocomplete>
                        </LabelledField>
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-col cols="12" md="12">
                        <LabelledField label="Select a fee" required>
                            <v-autocomplete :items="feeValues" :item-text="getFeeName" :item-value="(item) => item" outlined
                                dense hide-no-data hide-details="auto" class="text-field-background"
                                v-model="selectedFeeValue" :disabled="selectedFeeFieldDisabled" clearable
                                :messages="feeFieldMessage" :rules="[fieldRequired]"></v-autocomplete>
                        </LabelledField>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="4">
                        <LabelledField label="Unit Cost">
                            <v-text-field class="text-field-background" outlined dense required
                                :value="getFormattedMoney(unitCost)" hide-details="auto" disabled />
                        </LabelledField>
                    </v-col>

                    <v-col v-if="!feeIsPerYear" cols="12" md="4">
                        <LabelledField label="Quantity" required>
                            <v-text-field class="text-field-background" outlined dense required v-model="quantity"
                                hide-details="auto" clearable :rules="[fieldRequired, isNumber, quantityFieldMinValueRule,
                                    quantityAllowsDecimalPlaces ? null : isWholeNumber]"
                                :disabled="!selectedFeeValueNotEmpty" />
                        </LabelledField>
                    </v-col>

                    <v-col cols="12" md="4">
                        <LabelledField label="Total">
                            <v-text-field class="text-field-background" outlined dense required
                                :value="getFormattedMoney(total)" hide-details="auto" disabled />
                        </LabelledField>
                    </v-col>
                </v-row>

                <v-row justify="end">
                    <v-col cols="auto">
                        <v-btn depressed color="success" class="text-none" type="submit">
                            Next
                            <v-icon right>mdi-arrow-right</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </template>

        <template v-else>
            <v-row>
                <v-col cols="12" md="12">
                    <v-skeleton-loader type="button" class="skeleton-fill-width" />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-skeleton-loader type="button" class="skeleton-fill-width" />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="4">
                    <v-skeleton-loader type="button" class="skeleton-fill-width" />
                </v-col>
                <v-col cols="12" md="4">
                    <v-skeleton-loader type="button" class="skeleton-fill-width" />
                </v-col>
                <v-col cols="12" md="4">
                    <v-skeleton-loader type="button" class="skeleton-fill-width" />
                </v-col>
            </v-row>
            <v-row justify="end">
                <v-col cols="3">
                    <v-skeleton-loader type="button" class="skeleton-fill-width" />
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>