<script setup>
import { countyIsKirinyaga } from '@/utils/vue_helpers';
import { fieldRequired } from '@/utils/input_validation';
import BillItems from '@/components/billing/BillItems.vue';
import { ref } from 'vue'

const props = defineProps({
    billItems: {
        type: Array,
        default: null
    },
    generatingBill: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['generateBillClicked'])

const remarksModel = ref('')

const billRemarksForm = ref(null)

function onFormSubmitted() {
    const formValid = billRemarksForm.value.validate()
    if (formValid) {
        emit('generateBillClicked', { remarks: remarksModel.value })
    }
}
</script>

<template>
    <v-container>
        <v-row justify="center" class="title primary--text">
            <v-col cols="1">
                <v-tooltip top>
                    <template #activator="{ on, attrs }">
                        <v-btn icon @click="$emit('goBackClicked')" v-on="on" v-bind="attrs">
                            <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>
                    </template>
                    <span>Back</span>
                </v-tooltip>
            </v-col>
            <v-col cols="10" class="text-center">
                Generate Bill
            </v-col>
            <v-spacer />
        </v-row>

        <v-row>
            <v-col cols="12">
                <BillItems :billItems="billItems" :total="billItems[0].sub_total" />
            </v-col>
        </v-row>

        <v-form ref="billRemarksForm" @submit.prevent="onFormSubmitted">
            <v-row v-if="countyIsKirinyaga">
                <v-col cols="12" md="6">
                    <LabelledField label="Remarks" required>
                        <v-textarea outlined dense class="text-field-background" hide-details="auto" v-model="remarksModel"
                            :rules="[fieldRequired]" />
                    </LabelledField>
                </v-col>
            </v-row>

            <v-row justify="end">
                <v-col cols="auto">
                    <v-btn depressed color="success" class="text-none" type="submit" :loading="generatingBill">
                        Generate Bill
                        <v-icon right>mdi-receipt-text</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>