<script setup>
import { useGetFeesForService } from '../services_business_logic';
import SelectFee from './SelectFee.vue';
import Location from '@/components/billing/general_billing/Location.vue';
import ViewBill from '@/components/billing/view_bills/ViewBill.vue';
import { arrayNotEmpty } from '@/utils/functions';
import { computed, ref, watch } from 'vue';
import ViewServiceBillItems from './ViewServiceBillItems.vue';
import { useGenerateBill, useSubcountyWard, useRouter } from '@/utils/vue_helpers';
import { setSnackbar } from '@/components/utils/snackbar/logic';

const props = defineProps({
    title: {
        type: String,
        default: null
    },
    serviceName: {
        type: String,
        default: null,
    }
})

const billItems = ref(null)

const billToView = ref(null)

const selectFeesForm = ref(null)

const pages = {
    SELECT_FEE: 0,
    BILL_ITEMS: 1,
    VIEW_BILL: 2
}

const currentPage = ref(pages.SELECT_FEE)

const goTo = {
    selectfee() {
        currentPage.value = pages.SELECT_FEE
    },
    viewBillItems(billItem) {
        const formValid = selectFeesForm.value.validate()
        if (formValid) {
            billItems.value = [billItem]
            currentPage.value = pages.BILL_ITEMS
        }
    },
    viewBill(bill) {
        billToView.value = bill
        currentPage.value = pages.VIEW_BILL
    }
}

const location = useSubcountyWard()

const { route, routeNotEmpty } = useRouter()

const serviceNameToUse = computed(()=>{
    if(routeNotEmpty.value){
        return route.value.query['service-name']
    }
    return props.serviceName
})

const fees = useGetFeesForService({ service: serviceNameToUse })

const generateBill = useGenerateBill()

function onGenerateBillClicked({ remarks }) {
    const billData = {
        billItems: billItems.value,
        remarks,
        subcountyId: location.locationData.subcountyId,
        wardId: location.locationData.wardId,
        townId: location.locationData.townId
    }
    generateBill.mutate(billData, {
        onSuccess({ bill }) {
            goTo.viewBill(bill)
            setSnackbar({
                text: 'Bill Generated',
                color: 'success',
                open: true
            })
        }
    })
}
</script>

<template>
    <v-container>
        <v-row justify="center" class="mb-6">
            <v-col cols="auto" class="text-h5 font-weight-bold primary--text">
                {{ title }}
            </v-col>
        </v-row>

        <v-tabs-items v-model="currentPage">
            <v-tab-item :value="pages.SELECT_FEE">
                <v-row justify="center">
                    <v-col cols="12" md="12">
                        <v-form ref="selectFeesForm">
                            <v-row>
                                <v-col cols="12">
                                    <Location :subcountyId.sync="location.locationData.subcountyId"
                                        :wardId.sync="location.locationData.wardId"
                                        :townId.sync="location.locationData.townId"
                                        :subcounties="location.subcounties.value" :wards="location.wards.value"
                                        :towns="location.towns.value"
                                        :locationDataLoading="location.locationDataLoading.value" />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <SelectFee :fees="fees.data.value" :fetchingFees="fees.isLoading.value"
                                        @nextClicked="goTo.viewBillItems" />
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                </v-row>
            </v-tab-item>

            <v-tab-item :value="pages.BILL_ITEMS">
                <ViewServiceBillItems v-if="arrayNotEmpty(billItems)" :billItems="billItems"
                    :generatingBill="generateBill.isLoading.value" @goBackClicked="goTo.selectfee"
                    @generateBillClicked="onGenerateBillClicked" />
            </v-tab-item>

            <v-tab-item :value="pages.VIEW_BILL">
                <ViewBill v-if="!!billToView" :bill="billToView" showGoBack @goBackClicked="goTo.selectfee">
                    <template #title>
                        <div class="title text-center primary--text">
                            Bill Details
                        </div>
                    </template>
                </ViewBill>
            </v-tab-item>
        </v-tabs-items>
    </v-container>
</template>