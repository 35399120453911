import apiCall from "@/utils/api"
import { useQuery } from "@tanstack/vue-query"

export function useGetFeesForService({ service }) {
    async function fetchFees({ queryKey }) {
        const [{ service }] = queryKey
        const url = `/api/client/bill/get-service-fees?service=${service}`
        try {
            const response = apiCall({
                url,
                method: 'GET'
            })
            return response
        } catch (error) {
            throw (error)
        }
    }

    const feesQuery = useQuery({
        queryFn: fetchFees,
        queryKey: [{ service }],
        refetchOnWindowFocus: false,
        cacheTime: 20 * 60 * 100,
        staleTime: Infinity
    })

    return feesQuery
}